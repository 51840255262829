import React from "react"
import Img from "gatsby-image"
import { AnchorLink } from "gatsby-plugin-anchor-links";

function Banner(data) {
    return (
        <div>
            <div className="banner flex flex-col lg:flex-row">
            <div className="w-full lg:w-7/12 shape-two h-70 md:h-80 lg:h-auto bg-gray-300 2xl:max-h-100">
                <div className="h-full w-full">
                    <Img fluid={data.data.visionBannerImage.localFile.childImageSharp.fluid} alt={data.data.visionBannerImage.altText} className="w-full h-full object-cover object-fit mb-0" />
                </div>
            </div>
            <div className="shape-one bg-blue-200 lg:w-7/12 pt-12 lg:pt-20 lg:pt-26 pb-10 lg:pb-24 z-20 -mt-12 lg:-mt-0 px-3 md:px-6 lg:-ml-28">
                <div className="w-full lg:w-10/12 xl:w-7/12 lg:ml-20 xl:ml-32">
                    <div className="flex">
                        <div className="text-center lg:text-left">
                            <h2 className="relative vision-title text-white font-medium text-4xl lg:text-6xl mb-6">{data.data.visionBannerTitle}</h2>
                            <p className="text-sm lg:text-base leading-snug lg:leading-normal text-white mb-8">{data.data.visionBannerText}</p>
                            {(data.data.visionBannerButtonText != null) ?
                            <AnchorLink className="flex w-max bg-white hover:bg-transparent rounded-full px-10 py-3 text-blue-400 hover:text-white text-xs font-semibold mx-auto lg:ml-0 border-2 border-white" to={data.data.visionBannerButtonLink} title="Our team">
                                <span>{data.data.visionBannerButtonText}</span>
                            </AnchorLink> : ''
                            }
                            {(data.data.visionBannerBottomText != null) ?
                                <div className="flex justify-center lg:justify-start">
                                    <p className="relative vision-cta text-white text-xs pt-2 mt-6"><span className="text-white opacity-60">{data.data.visionBannerBottomText}</span></p>
                                </div>
                                : ''
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div className="h-10" id="find-help"></div>
        </div>
    );
}

export default Banner