import React from "react"
import Img from "gatsby-image"

function Vision(data) {
    return (
        <div className="container mb-10 px-3 md:px-6">
            <div className="flex text-center w-max mx-auto relative mt-2">
                <div className="bg-green-400 h-2 w-8 rounded-full mr-4"></div>
                <div className="bg-blue-400 h-2 w-16 rounded-full"></div>
            </div>
            <div className="w-full lg:w-10/12 xl:w-2/3 mx-auto">
                <h2 className="text-4xl lg:text-5xl text-blue-400 lg:text-blue-400 font-medium leading-tight text-center mb-2 lg:mb-5 mt-8 lg:mt-12 ">{data.data.ourVisionFeaturesTitle}</h2>
                <p className="text-gray-600 text-sm-base lg:text-base leading-normal font-medium text-center mb-0">{data.data.ourVisionFeaturesDescription}</p>
            </div>
            <div className="js-features lg:pt-10 lg:pb-6">
                {Object.keys(data.features.edges).length > 0 ?
                    (data.features.edges.map((feature) =>
                        data.features.edges.indexOf(feature) % 2 === 0 ?
                            <div key={data.features.edges.indexOf(feature)} className="w-full flex flex-col md:flex-row mt-12 text-center lg:text-left items-center">
                                <div className="w-full md:w-1/2 md:pr-4">
                                    <Img fluid={feature.node.OurVisionFeature.ourVisionFeatureImage.localFile.childImageSharp.fluid} alt={feature.node.OurVisionFeature.ourVisionFeatureImage.altText} className="mb-0 w-full rounded-lg" />
                                </div>
                                <div className="w-full md:w-1/2 md:pl-4 h-fit-content">
                                    <h3 className="text-blue-400 text-2xl font-medium mb-4 mt-3 lg:mt-0">{feature.node.title} </h3>
                                    <p className="text-gray-600 leading-relaxed text-xs mb-0" dangerouslySetInnerHTML={{ __html: feature.node.OurVisionFeature.ourVisionFeatureText }}></p>
                                </div>
                            </div>
                            :
                            <div key={data.features.edges.indexOf(feature)} className="w-full flex flex-col-reverse md:flex-row mt-12 mb-12 text-center lg:text-left items-center">
                                <div className="w-full md:w-1/2 md:pr-4 h-fit-content">
                                    <h3 className="text-blue-400 text-2xl font-medium mb-4 mt-3 lg:mt-0">{feature.node.title}</h3>
                                    <p className="text-gray-600 leading-relaxed text-xs mb-0" dangerouslySetInnerHTML={{ __html: feature.node.OurVisionFeature.ourVisionFeatureText }}></p>
                                </div>
                                <div className="w-full md:w-1/2 md:pl-4">
                                    <Img fluid={feature.node.OurVisionFeature.ourVisionFeatureImage.localFile.childImageSharp.fluid} alt={feature.node.OurVisionFeature.ourVisionFeatureImage.altText} className="mb-0 w-full rounded-lg" />
                                </div>
                            </div>
                    ))
                    : ''
                }
            </div>
        </div>
    );
}

export default Vision