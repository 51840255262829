import React, { useEffect, useState } from "react"
import InvestorPopup from "../components/common/investorPopup"
import { useStaticQuery, graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Header from "../components/header"
import Banner from "../components/our-vision/banner"
import Vision from "../components/our-vision/vision"
import Cta from "../components/our-vision/cta"
import "../css/our-vision.css"

const OurVision = () => {
  const homeSections = useStaticQuery(graphql`
    query OurVisionSections {
      allWpPage(filter: { id: { eq: "cG9zdDoxMjE=" } }) {
        edges {
          node {
            id
            title
            OurVisionBanner {
              visionBannerBottomText
              visionBannerButtonLink
              visionBannerButtonText
              visionBannerText
              visionBannerTitle
              visionBannerImage {
                altText
                localFile {
                  childImageSharp {
                    fluid(maxWidth: 1300, quality: 100) {
                      ...GatsbyImageSharpFluid_withWebp
                    }
                  }
                }
              }
            }
            OurVisionFeatures {
              ourVisionFeaturesDescription
              ourVisionFeaturesTitle
            }
            OurVisionFooterTop {
              visionFooterTopButtonLink
              visionFooterTopButtonText
              visionFooterTopTitle
            }
          }
        }
      }
      allWpOurVisionFeature(
        filter: { status: { eq: "publish" } }
        sort: { fields: date, order: ASC }
      ) {
        edges {
          node {
            id
            title
            OurVisionFeature {
              ourVisionFeatureText
              ourVisionFeatureImage {
                altText
                localFile {
                  childImageSharp {
                    fluid(maxWidth: 900, quality: 100) {
                      ...GatsbyImageSharpFluid_withWebp
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  `)
  const [investPopup, setInvestPopup] = useState(false)
  useEffect(() => {
    setTimeout(() => setInvestPopup(true), 7000)
  }, [])

  return (
    <Layout>
      <SEO
        title="Our vision"
        description={
          homeSections.allWpPage.edges[0].node.OurVisionBanner.visionBannerText
        }
      />
      <Header class="main-container fixed w-full z-50 top-0" />
      <div className="main-container our-vision pt-20 lg:pt-24">
        <Banner data={homeSections.allWpPage.edges[0].node.OurVisionBanner} />
        <Vision
          data={homeSections.allWpPage.edges[0].node.OurVisionFeatures}
          features={homeSections.allWpOurVisionFeature}
        />
        <Cta data={homeSections.allWpPage.edges[0].node.OurVisionFooterTop} />
        {investPopup ? (
          <InvestorPopup setInvestPopup={setInvestPopup} />
        ) : (
          <></>
        )}
      </div>
    </Layout>
  )
}

export default OurVision
