import React from "react"
import { AnchorLink } from "gatsby-plugin-anchor-links";

function Cta(data) {
    return (
        <div className="bg-gray-200 pt-12 -mb-20 pb-32 lg:pb-24">
            <div className="container px-3 md:px-6 w-full lg:w-10/12 xl:w-2/3 mx-auto text-center">
                <h2 className="text-3xl lg:text-5xl text-blue-400 lg:text-blue-400 font-medium leading-tight text-center mb-16">{data.data.visionFooterTopTitle}</h2>
                {data.data.visionFooterTopButtonText != null ?
                    <AnchorLink to={data.data.visionFooterTopButtonLink} className="bg-blue-400 hover:bg-gray-300 text-white text-xs px-12 py-4 rounded-full no-wrap">{data.data.visionFooterTopButtonText}</AnchorLink>
                : ''}
                <div className="bg-yellow-200 h-2 w-16 lg:w-16 rounded-full mt-8 mx-auto"></div>
            </div>
        </div>
    );
}

export default Cta;